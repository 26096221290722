<template>
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2023 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Lawrence Livermore National Laboratory</li>
              <li>EPC Groupe</li>
              <li>Northrop Grumman Corporation</li>
              <li>Northrop Grumman Innovation Systems - Corporate</li>
              <li>NASA</li>
              <li>Tooele Army Depot</li>
              <li>OLIN-WINCHESTER</li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>Brigham Young University</li>
              <li>Sandia National Laboratories</li>
              <li>US Dept. of Homeland Security (DHS)</li>
              <li>Los Alamos National Laboratory</li>
              <li>Northrop Grumman Corporation-ABL</li>
              <li>Safety Management Services, Inc.</li>
              <li>
                New Mexico Tech - Energetic Materials Research and Testing
                Center
              </li>
              <li>Federal Bureau of Investigation</li>
              <li>Hill Air Force Base</li>
              <li>SAAMI</li>
              <li>Safety Management Services, Inc.</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2023 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F001%20%26%20002%20-%20Welcome%20and%20Review%20of%20ET%20Users%20Group%20Charter%20-%20Robert%20Ford%20-%20SMS.pdf?alt=media&token=cc540d69-fb07-4321-af98-d7cdd3b12fd6"
                        >001 & 002 - Welcome and Review of ET Users Group
                        Charter - Robert Ford - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F003%20-%20UN%20EWG%20Round%20Robin%20Results%202023%20Koenen%20-%20Tom%20Pleva%20-%20EMRTC.pdf?alt=media&token=d7affb58-93d9-4094-b651-9fe5ac466d11"
                        >003 - UN EWG Round Robin Results 2023 Koenen - Tom
                        Pleva - EMRTC</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F006%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=d45ce4e9-d2d0-4926-94d3-bd86d999b89a"
                        >006 - UN EWG International Koenen Round-Robin Test
                        Results - Jack Zarbock - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F007%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=4d7af827-02f3-4492-9c05-225074488ad4"
                        >007 - UN EWG International Koenen Round-Robin Test
                        Results - Bob Ford - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F007a%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Kirt%20Sasser%20-%20SMS.pdf?alt=media&token=a48c5f62-02af-4541-be38-3854dcc3e46c"
                        >007a - UN EWG International Koenen Round-Robin Test
                        Results - Kirt Sasser - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F008%20-%20History%20of%20Hazards%20Analysis%20and%20HA%20Techniques%20-%20Boyd%20Hill%20-%20Northrop%20Grumman.pdf?alt=media&token=da4e0c34-b3a5-4af3-bc4e-cae9966b076f"
                        >008 - History of Hazards Analysis and HA Techniques -
                        Boyd Hill - Northrop Grumman</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F009%20-%20Mitigating%20Fire%20Incidents%20Exploring%20Potentially%20Reduced%20Flash%20Points%20in%20Manufacturing%20Mixtures%20-%20Clint%20Guymon%20-%20BYU.pdf?alt=media&token=6b3d6276-9f5d-4d04-9b7f-b599de1063c3"
                        >009 - Mitigating Fire Incidents Exploring Potentially
                        Reduced Flash Points in Manufacturing Mixtures - Clint
                        Guymon - BYU</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F010%20-%20AORC%20Guideline%20for%20Electrical%20Classification%20of%20Explosives%20Operating%20Buildings%20-%20Scott%20Genta%20-%20SMS.pdf?alt=media&token=c9ef52e9-bae1-4c1d-bd89-2b6df3e93faa"
                        >010 - AORC Guideline for Electrical Classification of
                        Explosives Operating Buildings - Scott Genta - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F010a%20-%20AORC%20Guideline%20for%20Electrical%20Classification%20of%20Explosives%20Operating%20Buildings%20-%20SMS.pdf?alt=media&token=b431b6be-c9a9-42cd-9a1e-07ca656f33c2"
                        >010a - AORC Guideline for Electrical Classification of
                        Explosives Operating Buildings - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F011%20-%20NFPA%20495%20Electrical%20Section%20-%20Larry%20Lyon%20-%20SMS.pdf?alt=media&token=463ea2b9-fd4d-4679-9d5e-ba2a39231e21"
                        >011 - NFPA 495 Electrical Section - Larry Lyon - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F012%20-%20HD%201.3%20Overpressure%20Modeling%20-%20Jacob%20Burrell%20-%20SMS.pdf?alt=media&token=b605379f-7565-40c7-9702-c855e0a63219"
                        >012 - HD 1.3 Overpressure Modeling - Jacob Burrell -
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F013%20-%20European%20Standards%20Revisions%20Overview%20-%20Lionel%20Aufauvre%20-%20EPC%20Groupe.pdf?alt=media&token=15e1b794-4999-4210-95ce-96fe449dc2cb"
                        >013 - European Standards Revisions Overview - Lionel
                        Aufauvre - EPC Groupe</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F014%20-%20The%20Effect%20of%20Sample%20Size%20on%20Impact%20Sensitivity%20of%20PETN%20-%20Koroglu%20Batikan%20-%20LLNL.pdf?alt=media&token=fa540919-371d-4eb7-bce9-7e048c55245e"
                        >014 - The Effect of Sample Size on Impact Sensitivity
                        of PETN - Koroglu Batikan - LLNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F015%20-%20Evaluation%20fo%20an%20Experimental%20Expanded%20Analogy%20Test%20Methodology%20for%20Small%20Arms%20Primers%20-%20Alan%20Serven%20-%20SAAMI.pdf?alt=media&token=96cb6781-267a-4945-b888-8bc21611a2f0"
                        >015 - Evaluation fo an Experimental Expanded Analogy
                        Test Methodology for Small Arms Primers - Alan Serven -
                        SAAMI</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F016%20-%20Sensitivity%20Test%20Equipment%20Maintenance%20-%20Rusty%20Christensen%20-%20SMS.pdf?alt=media&token=f003a6b1-9c28-4a43-8365-c3f7f2803361"
                        >016 - Sensitivity Test Equipment Maintenance - Rusty
                        Christensen - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F017%20-%20Thermal%20Decomposition%20of%20Isotopically%20Labeled%20TATB%20-%20Nicole%20Anderson%20-%20LLNL.pdf?alt=media&token=ba9aaebc-a9c6-47e9-9cb9-6447d73b1061"
                        >017 - Thermal Decomposition of Isotopically Labeled
                        TATB - Nicole Anderson - LLNL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F018%20-%20DSC%20Round%20Robin%20Results%20PETN%20and%20HMX%20Testing%20-%20Jason%20Phillips%20-%20Sandia.pdf?alt=media&token=474df6b9-a582-4fda-a868-e591d5ecca8d"
                        >018 - DSC Round Robin Results PETN and HMX Testing -
                        Jason Phillips - Sandia</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F019%20-%20Promontory%202023%20DSC%20Round%20Robin%20Results%20-%20Jacob%20Bauman%20-%20Northrop%20Grumman.pdf?alt=media&token=52de48f7-2321-4dc1-91cf-f44d8e4553d7"
                        >019 - Promontory 2023 DSC Round Robin Results - Jacob
                        Bauman - Northrop Grumman</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F020%20-%20SMS%20DSC%20Round%20Robin%20-%20Mark%20Frum%20-%20Northrop%20Grumman.pdf?alt=media&token=249b6f9a-d5b0-45b3-b388-c66edd20693e"
                        >020 - SMS DSC Round Robin - Mark Frum - Northrop
                        Grumman</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F021%20-%20SMS%20DSC%20Laboratory%20Test%20Results%20-%20Jack%20Zarbock.pdf?alt=media&token=350fbeaf-39ef-415f-bae8-1f7b93edca1e"
                        >021 - SMS DSC Laboratory Test Results - Jack Zarbock</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F022%20-%202022%20DSC%20Round%20Robin%20Results%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=2fe67b51-f2b9-4455-a81d-332a70fdf030"
                        >022 - 2022 DSC Round Robin Results - Jack Zarbock -
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F023%20-%20Considerations%20for%20Smokeless%20Powders%20Factors%20Affecting%20Reaction%20Severity%20-%20Troy%20Gardner%20-%20SMS.pdf?alt=media&token=70b691b1-47e6-4472-9e01-a519d9bd11ea"
                        >023 - Considerations for Smokeless Powders Factors
                        Affecting Reaction Severity - Troy Gardner - SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F024%20-%20Hazard%20Testing%20of%20Wetted%20Smokeless%20Propellant%20-%20Alan%20Serven%20-%20SAAMI.pdf?alt=media&token=6139ea2b-623b-46be-94d4-93da99a7f717"
                        >024 - Hazard Testing of Wetted Smokeless Propellant -
                        Alan Serven - SAAMI</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>

[
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F001%20%26%20002%20-%20Welcome%20and%20Review%20of%20ET%20Users%20Group%20Charter%20-%20Robert%20Ford%20-%20SMS.pdf?alt=media&token=cc540d69-fb07-4321-af98-d7cdd3b12fd6">001 & 002 - Welcome and Review of ET Users Group Charter - Robert Ford - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F003%20-%20UN%20EWG%20Round%20Robin%20Results%202023%20Koenen%20-%20Tom%20Pleva%20-%20EMRTC.pdf?alt=media&token=d7affb58-93d9-4094-b651-9fe5ac466d11">003 - UN EWG Round Robin Results 2023 Koenen - Tom Pleva - EMRTC</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F006%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=d45ce4e9-d2d0-4926-94d3-bd86d999b89a">006 - UN EWG International Koenen Round-Robin Test Results - Jack Zarbock - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F007%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Bob%20Ford%20-%20SMS.pdf?alt=media&token=4d7af827-02f3-4492-9c05-225074488ad4">007 - UN EWG International Koenen Round-Robin Test Results - Bob Ford - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F007a%20-%20UN%20EWG%20International%20Koenen%20Round-Robin%20Test%20Results%20-%20Kirt%20Sasser%20-%20SMS.pdf?alt=media&token=a48c5f62-02af-4541-be38-3854dcc3e46c">007a - UN EWG International Koenen Round-Robin Test Results - Kirt Sasser - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F008%20-%20History%20of%20Hazards%20Analysis%20and%20HA%20Techniques%20-%20Boyd%20Hill%20-%20Northrop%20Grumman.pdf?alt=media&token=da4e0c34-b3a5-4af3-bc4e-cae9966b076f">008 - History of Hazards Analysis and HA Techniques - Boyd Hill - Northrop Grumman</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F009%20-%20Mitigating%20Fire%20Incidents%20Exploring%20Potentially%20Reduced%20Flash%20Points%20in%20Manufacturing%20Mixtures%20-%20Clint%20Guymon%20-%20BYU.pdf?alt=media&token=6b3d6276-9f5d-4d04-9b7f-b599de1063c3">009 - Mitigating Fire Incidents Exploring Potentially Reduced Flash Points in Manufacturing Mixtures - Clint Guymon - BYU</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F010%20-%20AORC%20Guideline%20for%20Electrical%20Classification%20of%20Explosives%20Operating%20Buildings%20-%20Scott%20Genta%20-%20SMS.pdf?alt=media&token=c9ef52e9-bae1-4c1d-bd89-2b6df3e93faa">010 - AORC Guideline for Electrical Classification of Explosives Operating Buildings - Scott Genta - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F010a%20-%20AORC%20Guideline%20for%20Electrical%20Classification%20of%20Explosives%20Operating%20Buildings%20-%20SMS.pdf?alt=media&token=b431b6be-c9a9-42cd-9a1e-07ca656f33c2">010a - AORC Guideline for Electrical Classification of Explosives Operating Buildings - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F011%20-%20NFPA%20495%20Electrical%20Section%20-%20Larry%20Lyon%20-%20SMS.pdf?alt=media&token=463ea2b9-fd4d-4679-9d5e-ba2a39231e21">011 - NFPA 495 Electrical Section - Larry Lyon - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F012%20-%20HD%201.3%20Overpressure%20Modeling%20-%20Jacob%20Burrell%20-%20SMS.pdf?alt=media&token=b605379f-7565-40c7-9702-c855e0a63219">012 - HD 1.3 Overpressure Modeling - Jacob Burrell - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F013%20-%20European%20Standards%20Revisions%20Overview%20-%20Lionel%20Aufauvre%20-%20EPC%20Groupe.pdf?alt=media&token=15e1b794-4999-4210-95ce-96fe449dc2cb">013 - European Standards Revisions Overview - Lionel Aufauvre - EPC Groupe</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F014%20-%20The%20Effect%20of%20Sample%20Size%20on%20Impact%20Sensitivity%20of%20PETN%20-%20Koroglu%20Batikan%20-%20LLNL.pdf?alt=media&token=fa540919-371d-4eb7-bce9-7e048c55245e">014 - The Effect of Sample Size on Impact Sensitivity of PETN - Koroglu Batikan - LLNL</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F015%20-%20Evaluation%20fo%20an%20Experimental%20Expanded%20Analogy%20Test%20Methodology%20for%20Small%20Arms%20Primers%20-%20Alan%20Serven%20-%20SAAMI.pdf?alt=media&token=96cb6781-267a-4945-b888-8bc21611a2f0">015 - Evaluation fo an Experimental Expanded Analogy Test Methodology for Small Arms Primers - Alan Serven - SAAMI</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F016%20-%20Sensitivity%20Test%20Equipment%20Maintenance%20-%20Rusty%20Christensen%20-%20SMS.pdf?alt=media&token=f003a6b1-9c28-4a43-8365-c3f7f2803361">016 - Sensitivity Test Equipment Maintenance - Rusty Christensen - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F017%20-%20Thermal%20Decomposition%20of%20Isotopically%20Labeled%20TATB%20-%20Nicole%20Anderson%20-%20LLNL.pdf?alt=media&token=ba9aaebc-a9c6-47e9-9cb9-6447d73b1061">017 - Thermal Decomposition of Isotopically Labeled TATB - Nicole Anderson - LLNL</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F018%20-%20DSC%20Round%20Robin%20Results%20PETN%20and%20HMX%20Testing%20-%20Jason%20Phillips%20-%20Sandia.pdf?alt=media&token=474df6b9-a582-4fda-a868-e591d5ecca8d">018 - DSC Round Robin Results PETN and HMX Testing - Jason Phillips - Sandia</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F019%20-%20Promontory%202023%20DSC%20Round%20Robin%20Results%20-%20Jacob%20Bauman%20-%20Northrop%20Grumman.pdf?alt=media&token=52de48f7-2321-4dc1-91cf-f44d8e4553d7">019 - Promontory 2023 DSC Round Robin Results - Jacob Bauman - Northrop Grumman</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F020%20-%20SMS%20DSC%20Round%20Robin%20-%20Mark%20Frum%20-%20Northrop%20Grumman.pdf?alt=media&token=249b6f9a-d5b0-45b3-b388-c66edd20693e">020 - SMS DSC Round Robin - Mark Frum - Northrop Grumman</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F021%20-%20SMS%20DSC%20Laboratory%20Test%20Results%20-%20Jack%20Zarbock.pdf?alt=media&token=350fbeaf-39ef-415f-bae8-1f7b93edca1e">021 - SMS DSC Laboratory Test Results - Jack Zarbock</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F022%20-%202022%20DSC%20Round%20Robin%20Results%20-%20Jack%20Zarbock%20-%20SMS.pdf?alt=media&token=2fe67b51-f2b9-4455-a81d-332a70fdf030">022 - 2022 DSC Round Robin Results - Jack Zarbock - SMS</a>
<a
  class="mb-2"
  style="color:#724009;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F023%20-%20Considerations%20for%20Smokeless%20Powders%20Factors%20Affecting%20Reaction%20Severity%20-%20Troy%20Gardner%20-%20SMS.pdf?alt=media&token=70b691b1-47e6-4472-9e01-a519d9bd11ea">023 - Considerations for Smokeless Powders Factors Affecting Reaction Severity - Troy Gardner - SMS</a>
<a
  class="mb-2"
  style="color:#ef9a3d;"
  target="_blank"
  href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2023%2F024%20-%20Hazard%20Testing%20of%20Wetted%20Smokeless%20Propellant%20-%20Alan%20Serven%20-%20SAAMI.pdf?alt=media&token=6139ea2b-623b-46be-94d4-93da99a7f717">024 - Hazard Testing of Wetted Smokeless Propellant - Alan Serven - SAAMI</a>
]
